import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import lolRefs from "@/game-lol/refs.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import getTierIcon from "@/shared/get-tier-icon.mjs";
import { calcRate } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

function duoTier(winrate) {
  switch (true) {
    case winrate >= 0.54:
      return 1;
    case winrate >= 0.52:
      return 2;
    case winrate >= 0.5:
      return 3;
    case winrate >= 0.48:
      return 4;
    default:
      return 5;
  }
}

const useChampionsSynergies = (searchParams) => {
  const filters = getDefaultedFiltersForChampions(searchParams, "synergies");
  const urlParams = getSearchParamsForChampions(true, filters);

  const { t } = useTranslation();
  const {
    lol: { championSynergies },
  } = useSnapshot(readState);

  const stats = championSynergies?.[urlParams.toString()];
  const champions = getStaticData("champions");

  const rows = useMemo(() => {
    if (!champions || !stats || stats instanceof Error) return null;

    const linkParams = new URLSearchParams(searchParams);

    return stats.map((champStats) => {
      const { championId, champion, duoChampionId, duoChampion, games } =
        champStats;

      const champion1Key = champions?.keys?.[championId];
      const champion2Key = champions?.keys?.[duoChampionId];
      const champion1 = champions?.[champion1Key];
      const champion2 = champions?.[champion2Key];

      if (!champion1 || !champion2) return null;

      const champion1Name = champion1.name;
      const champion2Name = champion2.name;

      const champion1Params = new URLSearchParams(linkParams);
      champion1Params.delete("duoRole");
      champion1Params.set("role", champion1Params.get("role") || filters.role);

      const champion2Params = new URLSearchParams(linkParams);
      champion2Params.delete("duoRole");
      champion2Params.set(
        "role",
        champion2Params.get("duoRole") || filters.duoRole,
      );

      const champion1Link = `/${lolRefs.lolChampionPrefix}/${champion1Key}/build?${champion1Params}`;
      const champion2Link = `/${lolRefs.lolChampionPrefix}/${champion2Key}/build?${champion2Params}`;

      const Role1Icon = champStats.role && getRoleIcon(champStats.role);
      const Role2Icon = champStats.duoRole && getRoleIcon(champStats.duoRole);

      const wr = calcRate(champStats.wins, champStats.games);

      const tier = duoTier(wr);
      const TierIcon = getTierIcon(tier);

      return [
        {
          display:
            Role1Icon && Role2Icon ? (
              <>
                <Role1Icon style={{ height: 20, width: 20 }} />
                <Role2Icon style={{ height: 20, width: 20 }} />
              </>
            ) : null,
          value: null,
        },
        {
          display: (
            <>
              <a href={champion1Link}>
                <ChampionImg id={championId} size={28} />
              </a>
              <a href={champion2Link}>
                <ChampionImg id={duoChampionId} size={28} />
              </a>
              <span className="champion-name">
                <a href={champion1Link}>{champion1Name}</a>{" "}
                <a href={champion2Link}>{champion2Name}</a>
              </span>
            </>
          ),
          value: `${champion1Name} ${champion2Name}`,
          className: "champion-meta",
        },
        { display: <TierIcon />, value: tier * -1 },
        {
          display: getLocaleString(wr, percentOptions),
          value: wr,
          // style: { color: getWinRateColor(wr * 100) },
        },
        { display: getLocaleString(games), value: games },
        { display: getLocaleString(champion.games), value: games },
        {
          display: getLocaleString(duoChampion.games),
          value: duoChampion.games,
        },
      ];
    });
  }, [stats, champions, searchParams]);

  return {
    cols: [
      { display: t("lol:role", "Role"), align: "center" },
      { display: t("lol:champion", "Champion"), align: "left", primary: true },
      { display: t("common:tier", "Tier"), align: "center" },
      {
        display: t("common:duoWinRate", "Duo Win Rate"),
        align: "right",
        isStat: true,
      },
      { display: t("common:matches", "Matches"), align: "right", isStat: true },
      {
        display: t("lol:stat.champ1Matches", "Champ 1 Matches"),
        align: "right",
      },
      {
        display: t("lol:stat.champ2Matches", "Champ 2 Matches"),
        align: "right",
      },
    ],
    rows: rows,
  };
};

export default useChampionsSynergies;
