import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import {
  dataSinglesList,
  getKeyForArenaChampionsData,
} from "@/game-lol/utils/arena-queue-utils.mjs";
import { getStaticData } from "@/game-lol/utils/util.mjs";
import getTierIcon from "@/shared/get-tier-icon.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};
const numOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const useChampionsArena = () => {
  const { t } = useTranslation();
  const {
    lol: { championStats, arenaStatsPatch },
  } = useSnapshot(readState);

  const patch = arenaStatsPatch.patch;
  const key = getKeyForArenaChampionsData(patch);
  const stats = championStats?.[key];

  const champions = getStaticData("champions");

  const rows = useMemo(() => {
    if (!champions || !stats || stats instanceof Error) return null;

    const statsArr = dataSinglesList(stats, champions);

    return statsArr.map((champStats) => {
      const {
        championId,
        tier,
        avgPlace,
        top1Rate,
        top2Rate,
        // top4Rate,
        pickRate,
        // banRate,
        matches,
        href,
        duos = [],
      } = champStats;

      const championKey = champions?.keys?.[championId];
      const champion = champions?.[championKey];

      if (!champion || !championKey) return null;

      const championName = champion?.name;
      const TierIcon = getTierIcon(tier);

      return [
        {
          display: (
            <>
              <ChampionImg id={championId} size={28} />
              <span className="champion-name">{championName}</span>
            </>
          ),
          value: championName,
          className: "champion-meta",
          link: href,
        },
        { display: <TierIcon />, value: tier * -1 },
        {
          display: getLocaleString(avgPlace, numOptions),
          value: avgPlace * -1,
          // style: { color: placementColor(avgPlace) },
        },
        {
          display: getLocaleString(top1Rate, percentOptions),
          value: top1Rate,
          // style: { color: winRateColor(top1Rate * 100) },
        },
        {
          display: getLocaleString(top2Rate, percentOptions),
          value: top2Rate,
          // style: { color: top2Color(top2Rate * 100) },
        },
        // {
        //   display: getLocaleString(top4Rate, percentOptions),
        //   value: top4Rate,
        //   // style: { color: top2Color(top2Rate * 100) },
        // },
        { display: getLocaleString(pickRate, percentOptions), value: pickRate },
        // { display: getLocaleString(banRate, percentOptions), value: banRate },
        { display: getLocaleString(matches), value: matches },
        {
          display: (
            <div className="champion-duos">
              {duos.slice(0, 4).map((duo, i) => (
                <ChampionImg key={i} id={duo.teammate_champion_id} size={20} />
              ))}
            </div>
          ),
          value: null,
        },
      ];
    });
  }, [stats, champions]);

  return {
    cols: [
      { display: t("lol:champion", "Champion"), align: "left", primary: true },
      { display: t("common:tier", "Tier"), align: "center" },
      {
        display: t("common:avgPlace", "Avg. Place"),
        align: "right",
        isStat: true,
      },
      {
        display: t("lol:arena.firstPlace", "1st Place"),
        align: "right",
        isStat: true,
      },
      // { display: t("lol:arena.top2", "Top 2"), align: "right", isStat: true },
      { display: t("lol:arena.top4", "Top 4"), align: "right", isStat: true },
      {
        display: t("common:pickRate", "Pick Rate"),
        align: "right",
        isStat: true,
      },
      // {
      //   display: t("common:banRate", "Ban Rate"),
      //   align: "right",
      //   isStat: true,
      // },
      {
        display: t("common:appearances", "Appearances"),
        align: "right",
        isStat: true,
      },
      { display: t("common:duos", "Duos"), align: "right" },
    ],
    rows: rows,
  };
};

export default useChampionsArena;
